import { FindLoadsFiltersForm } from 'pages/find-loads-ver2/filters/find-loads-filters.form';
import { MinMaxFormGroup } from 'shared/find-loads/components/min-max-form-group.component';
import { Switch } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';

interface Props {
  form: FindLoadsFiltersForm;
  isMetric: boolean;
}

export const FindLoadsFiltersFormComponent = ({ form, isMetric }: Props) => {
  const { t } = useTranslation();
  const carrier = useStoredCarrierDetail();

  const distanceUnits = isMetric ? 'km' : 'mi';
  const values = form.value;

  return (
    <div className='filters-form-content' data-testid='filters-form-content' >
      <div className="filters-form-section">
        <div>
          <label>
            <div className="h3">
              {t('EQUIPMENT_LENGTH')}
            </div>
            <input
              type="text"
              id="equipment-length"
              name="equipment-length"
              className="form-control"
              aria-labelledby="equipment-length-label"
              value={values?.equipment?.length || ''}
              onChange={(e) => form.setNumericField('equipment.length', e)}
            />
          </label>
        </div>
      </div>
      <div className="filters-form-section">
        <MinMaxFormGroup
          fieldLabel={`(${distanceUnits})`}
          groupLabelKey="LOAD_DISTANCE"
          values={values?.distance || { min: '', max: '' }}
          onMinChange={(e) => form.setNumericField('distance.min', e)}
          onMaxChange={(e) => form.setNumericField('distance.max', e)}
        />
      </div>
      <div className="filters-form-section">
        <MinMaxFormGroup
          fieldLabel={t(isMetric ? 'KG' : 'LBS')}
          groupLabelKey="LOAD_WEIGHT"
          values={values?.weight || { min: '', max: '' }}
          onMinChange={(e) => form.setNumericField('weight.min', e)}
          onMaxChange={(e) => form.setNumericField('weight.max', e)}
        />
      </div>
      <div className="filters-form-section switch-label">
        <span className="js-field-team-loads-only switch-inline checkbox-inline">
          <Switch
            id="find-loads-filter-team-loads"
            label={t('TEAM_LOADS_ONLY')}
            ariaLabel={t('TEAM_LOADS_ONLY')}
            checked={values?.teamLoadsOnly}
            onChange={(e) => form.setBooleanField('teamLoadsOnly', e.target.checked)}
          />
        </span>
      </div>
      {carrier?.hasValidHazmatCertification() &&
        <div className="filters-form-section switch-label" data-testid='filter-hazmat-loads'>
          <span className="js-field-hazmat-loads-only switch-inline checkbox-inline">
            <Switch
              id="find-loads-filter-hazmat-loads"
              label={t('HAZMAT_LOADS_ONLY')}
              ariaLabel={t('HAZMAT_LOADS_ONLY')}
              checked={values?.hazmatLoadsOnly}
              onChange={(e) => form.setBooleanField('hazmatLoadsOnly', e.target.checked)}
            />
          </span>
        </div>
      }
      <div className="filters-form-section" data-testid='carrier-tier-available-only-selection'>
        <span className="js-field-carrier-tier-available-only switch-inline checkbox-inline">
          <Switch
            id="find-loads-filter-carrier-tier-available"
            label={t('CARRIER_TIER_AVAILABLE_ONLY')}
            ariaLabel={t('CARRIER_TIER_AVAILABLE_ONLY')}
            checked={values?.carrierTierAvailableOnly}
            onChange={(e) => form.setBooleanField('carrierTierAvailableOnly', e.target.checked)}
          />
        </span>
      </div>
      <div className="filters-form-section switch-label">
        <span className="js-field-hide-stf-loads switch-inline checkbox-inline" data-testid='filter-stf-loads'>
          <Switch
            id="find-loads-filter-stf-loads"
            label={t('STF_FILTER')}
            ariaLabel={t('STF_FILTER')}
            checked={values?.hideStfLoads}
            onChange={(e) => form.setBooleanField('hideStfLoads', e.target.checked)}
          />
        </span>
      </div>
      <div className="filters-form-section switch-label">
        <span className="js-field-hide-web-exclusive switch-inline checkbox-inline" data-testid='filter-web-exclusive'>
          <Switch
            id="find-loads-filter-web-exclusive"
            label={t('CARRIER_EXCLUSIVE_ONLY')}
            ariaLabel={t('CARRIER_EXCLUSIVE_ONLY')}
            checked={values?.webExclusiveOnly}
            onChange={(e) => form.setBooleanField('webExclusiveOnly', e.target.checked)}
          />
        </span>
      </div>
    </div>
  );
};