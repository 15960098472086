import { useCallback } from 'react';
import { Button, Span } from 'shared/components/elements/elements.components';
import { showModal } from 'shared/components/modal/modal.actions';
import { useDispatch } from 'react-redux';
import { FIND_LOADS_FILTERS_MODAL } from './find-loads-filters-modal.component';
import { useTranslation } from 'react-i18next';
import './find-loads-filters-button.component.scss'

export interface FiltersButtonProps {
  searchCriteria?: AvailableLoadSearchCriteriaJSON;
}

export const FiltersButton = ({ searchCriteria }: FiltersButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    milesMin,
    milesMax,
    weightMin,
    weightMax,
    equipmentLengthMax,
    teamLoad,
    hazmatLoad,
    carrierTierAvailable,
    stfLoad,
    webExclusive
  } = searchCriteria ?? {};

  const filterValues = [
    milesMin,
    milesMax,
    weightMin,
    weightMax,
    equipmentLengthMax,
    teamLoad,
    hazmatLoad,
    carrierTierAvailable,
    (stfLoad === false), // When stfLoad is false, "Hide STF loads" filter is applied.
    webExclusive
  ];
  const count = filterValues.filter(v => v).length;
  const ariaText = `(${t("NUM_FILTERS_APPLIED", { 0: count })})`;

  const handleClick = useCallback(() => {
    dispatch(showModal(FIND_LOADS_FILTERS_MODAL));
  }, []);

  return (
    <Button
      btnDefault
      id="find-loads-results-filters-button"
      className="no-wrap"
      onClick={handleClick}
    >
      <svg width="18" height="11" viewBox="0 0 18 11" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10.9109H11V9.143H7V10.9109ZM0 0.303711V2.07157H18V0.303711H0ZM3 6.49121H15V4.72335H3V6.49121Z" />
      </svg>
      <span className="filters-button-text">
        {t('FILTERS')}
        {count > 0 && (
          <Span ariaText={ariaText} data-testid="filter-count">
            {` (${count})`}
          </Span>
        )}
      </span>
    </Button>
  );
}
