import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchAnalytics } from 'features/analytics/hooks/use-search-analytics.hook';
import { useSortActionAnalytics } from 'features/analytics/hooks/use-sort-action-analytics.hook';
import { useForceUpdate } from 'app/hooks/use-force-update.hook';
import { LoaderNames, useLoader } from 'app/hooks/use-loader.hook';
import { breakpoints, useWindowSizeBreakpoints } from 'app/hooks/use-window-size-breakpoints.hook';
import { sortOrderFieldDefaults } from 'pages/find-loads-ver2/constants';
import { LoadDetail } from 'pages/find-loads-ver2/details/load-detail.component';
import { FiltersButton } from 'pages/find-loads-ver2/filters/find-loads-filters-button.component';
import { LOAD_DETAILS_MODAL } from 'app/globals/constants';
import { FindLoadsResult } from 'pages/find-loads-ver2/results//find-loads-result.component';
import { EmptySplashResults } from 'pages/find-loads-ver2/results/find-loads-results-empty-splash.component';
import { OptionalTitle } from 'pages/find-loads-ver2/results/optional-title';
import { AreaContext } from 'shared/components/analytics/area.context';
import { CardSortOption, SortConfig } from 'shared/components/data-table/data-table.interfaces';
import { CardView, DataTable, Pagination } from 'shared/components/data-table/data-table.lazy';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { LazyBoundary } from 'shared/components/lazy/lazy-boundary.component';
import { LoadingIndicatorSpinner } from 'shared/components/loading/inline-loaders/loading-indicator-spinner.component';
import { showModal } from 'shared/components/modal/modal.actions';
import { setPage, setPagination } from 'shared/components/pagination/pagination.reducer';
import { RefreshButton } from 'shared/components/refresher/refresh-button.component';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import { FooterPosition } from 'shared/enums/data-table-footer-position.enum';
import { HeaderPosition } from 'shared/enums/data-table-header-position.enum';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { SortOrder } from 'shared/enums/sort-order.enum';
import { useSortedResults } from 'shared/find-loads/hooks/use-sorted-results.hook';
import { clearFailedBook, setSearchCorrelationId } from 'shared/find-loads/redux/find-loads.actions';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { User } from 'shared/models/user.model';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { UUID } from 'angular2-uuid';
import { LoadsNotFound } from 'pages/find-loads-ver2/results/find-loads-results-not-found.component';
import { publishSearchSubmittedEvent, publishLoadClickedEvent } from 'app/repositories/analytics.repository';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { FindLoadsLazyModals } from 'pages/find-loads-ver2/results/find-loads-lazy-modals.component';
import { ReloadNotFoundBanner } from 'pages/find-loads-ver2/search/reload-not-found-banner.component';
import {
  EmbeddedContentCardFeed,
  EmbeddedContentCardPurposes,
  EmbeddedContentCardTypes,
  EmbeddedContentCardLocations
} from 'features/braze/embedded-content-cards/EmbeddedContentCardFeed';
import './find-loads-results.component.scss';

type Props = {
  searchType: AvailableLoadSearchType;
  sendLDEvents: boolean
}

const recommendedFieldColumn = 'recommendedFieldColumn';

function findSelectedReload(displayLoads: AvailableLoadSummary[], selectedReload?: number) {
  if (selectedReload) {
    return displayLoads?.find(l => l.number === selectedReload);
  }

  return null;
}

// tslint:disable-next-line:no-shadowed-variable
export const FindLoadsResults = ({ searchType, sendLDEvents }: Props) => {
  const defaultSortConfigOptions: SortConfig = {
    sortByColumnName: recommendedFieldColumn,
    sortOrder: sortOrderFieldDefaults[recommendedFieldColumn],
    skipSorting: true,
    includeEmptyOption: false
  };

  // store selectors
  const { searchCriteria, results, error, user, carrier } = useSelector(s => ({
    searchCriteria: s.findLoads.searchCriteria || null,
    results: s.findLoads.results,
    error: s.findLoads.error,
    user: s.auth.user,
    carrier: s.auth.carrier
  }));

  let searchCorrelationId = useSelector(s => s.findLoads.searchCorrelationId);

  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortConfigOptions);
  const userHasSorted = useRef<boolean>(false);
  const manualSortTriggered = useRef<boolean>(false);
  const isFilteredOnBin = false;
  const [pagingAndSortingInitialized, setPagingAndSortingInitialized] = useState<boolean>(true);
  const sortableLoads = useMemo(() => !!pagingAndSortingInitialized ? results.loads : null, [results.loads, pagingAndSortingInitialized]);
  const { findLoadsSearchCategorization, findLoadsSendAnalyticsWeb, findLoadsLoadNotificationWeb } = useFlags();

  const [displayLoads, refreshLoads, totalLoads] = useSortedResults(
    sortConfig,
    isFilteredOnBin,
    results.pagination,
    sortableLoads,
    findLoadsSearchCategorization
  );
  const [selectedLoad, setSelectedLoad] = useState<AvailableLoadSummary>(null);
  const isWebBreakpoint = useWindowSizeBreakpoints(breakpoints.lg_two_columns_cuttoff);
  const refreshTriggered = useRef<boolean>(false);
  const { dispatchSearchResultsEvent, dispatchEmptySearchResultsEvent, dispatchSubmitSearchEvent, dispatchRecommendedLoadsListViewedEvent } = useSearchAnalytics();
  const suggestedLoader = useLoader(LoaderNames.SuggestedLoads);
  const mainLoader = useLoader(LoaderNames.Main);
  const dispatchSortActionEvent = useSortActionAnalytics();

  const showLoadDetailModal = (load) => load && showModal(LOAD_DETAILS_MODAL, { load });
  const [selectedLoadResultRef, setSelectedLoadResultRef] = useState<HTMLElement>();
  const loadDetailRef = useRef<HTMLElement>();

  //#region Analytics
  const searchResultsEvent = useMemo(() => {
    if (!results?.loads || !displayLoads) {
      return null;
    }

    const { pagination: { pageNumber, pageSize }, loads } = results;
    return {
      searchResults: displayLoads, // Only the list that the UI shows should be tracked in the event.
      pageNumber,
      pageSize,
      totalVisibleResults: displayLoads?.length, // REVIEW: This doesn't get set until after sorting/filtering is performed.
      subsetOfResultsCount: loads?.filter(l => l.hasRateObject)?.length,
    };
  }, [results.loads, results.pagination.pageNumber, results.pagination.pageSize, displayLoads]);

  const doSearchActions = (searchCriteria: AvailableLoadSearchCriteriaJSON, user: User, isRecommended: boolean, sendAnalytics: boolean) => {
    const searchId = UUID.UUID();
    let searchType;
    if (isRecommended) {
      searchType = 'recommend';
    }
    else {
      searchType = searchCriteria.loadNumber ? 'load' : 'origin-destination';
    }
    // override props so we don't have to wait for redux to get updated
    searchCorrelationId = searchId;

    dispatch(setSearchCorrelationId(searchId));
    if (sendAnalytics) {
      publishSearchSubmittedEvent(searchType, searchId, searchCriteria, user, carrier?.carrierCode);
    }
  }

  const triggerRecommendedLoadsEventCallback = useCallback(() => {
    if (searchResultsEvent?.searchResults) {
      dispatchRecommendedLoadsListViewedEvent({
        ...searchResultsEvent,
        searchResults: null,
        recommendedLoads: searchResultsEvent.searchResults
      });
    }
  }, [searchResultsEvent?.searchResults]);

  const forceComponentUpdateForRecommendedLoadsEvent = useForceUpdate(triggerRecommendedLoadsEventCallback);

  const positionOnPage = useMemo(() => {
    return selectedLoad
      ? displayLoads?.reduce((result: number, load, index) => load.number === selectedLoad.number ? index + 1 : result, null)
      : undefined;
  }, [displayLoads, selectedLoad])

  useEffect(() => {
    if (searchResultsEvent) {
      if (searchCriteria?.loadNumber && results.performedSuggestedLoadSearch) {
        dispatchEmptySearchResultsEvent();
      }

      if (results.performedSuggestedLoadSearch) {
        doSearchActions(searchCriteria, user, true, findLoadsSendAnalyticsWeb);
        forceComponentUpdateForRecommendedLoadsEvent();
      } else {
        if (searchResultsEvent?.searchResults?.length > 0) {
          dispatchSearchResultsEvent(searchResultsEvent);
        } else {
          dispatchEmptySearchResultsEvent();
        }
      }
    }
  }, [!!searchResultsEvent]);

  useEffect(() => {
    if (results.pagination) {
      const { pageNumber, pageSize } = results.pagination;
      dispatchSearchResultsEvent({ ...searchResultsEvent, pageSize, pageNumber });
    }
  }, [results.pagination.pageNumber, results.pagination.pageSize]);

  useEffect(() => {
    if (searchCriteria?.reloadsCriteria) {
      dispatchSubmitSearchEvent(searchCriteria, UUID.UUID());
    }
  }, [searchCriteria?.reloadsCriteria]);

  useEffect(() => {
    if (searchResultsEvent?.searchResults?.length && manualSortTriggered.current) {
      const { sortByColumnName: sortBy, sortOrder } = sortConfig;
      dispatchSortActionEvent({
        sortBy,
        sortOrder,
        sortedResults: searchResultsEvent.searchResults,
      });
      if (sortBy === recommendedFieldColumn) {
        dispatchSubmitSearchEvent(searchCriteria, UUID.UUID());
        doSearchActions(searchCriteria, user, false, findLoadsSendAnalyticsWeb);
      }
    }
  }, [sortConfig.sortByColumnName, sortConfig.sortOrder, !!searchResultsEvent]);
  //#endregion Analytics

  const updateSelectedLoad = (loadToSelect: AvailableLoadSummary) => {
    if (selectedLoad?.number !== loadToSelect?.number) {
      setSelectedLoad(loadToSelect);
    }
  };

  // Set initially selected load after the data is loaded and the paging/sorting have been applied.
  useEffect(() => {
    const selectedLoadShouldBeUpdated = results?.pagination?.pageNumber
      && displayLoads?.length
      && !displayLoads?.find(l => selectedLoad?.number === l.number);

    if (selectedLoadShouldBeUpdated) {
      const selectedReload = findSelectedReload(displayLoads, searchCriteria?.reloadsCriteria?.selectedReloadLoadNumber)
      const localSelectedLoad = selectedReload ?? displayLoads[0]

      updateSelectedLoad(localSelectedLoad);

      if (findLoadsSendAnalyticsWeb) {
        const { pageNumber, pageSize } = results.pagination;
        publishLoadClickedEvent(localSelectedLoad, pageNumber, pageSize, searchCorrelationId, user, carrier?.carrierCode, true);
      }

      if (!!selectedReload) {
        loadDetailSelectedByUser(selectedReload);
      }
    }
  }, [displayLoads, findLoadsSendAnalyticsWeb]);

  useEffect(() => {
    // Only clear failedBooks when there are failed books to begin with.
    if (results?.failedBooks?.length > 0) {
      dispatch(clearFailedBook());
    }
  }, [results?.loads]);

  useEffect(() => {
    if (!results.loads) {
      setPagingAndSortingInitialized(false);
      setSelectedLoad(null);
    } else {
      // Initialize paging if needed
      if (results.loads?.length && results.pagination.pageNumber > 1) {
        dispatch(setPage('find-loads-results', 1));
      }

      if (!refreshTriggered.current) {
        // On a normal search, we'll always reinitialize the sorting.
        initializeSorting();
      }
      refreshTriggered.current = false;
      setPagingAndSortingInitialized(true);
      manualSortTriggered.current = false;
    }
  }, [results.loads]);

  // we are only maintaining user sort settings on OD search if a location is entered
  useEffect(() => {
    if (!searchCriteria?.originCity && !searchCriteria?.destinationCity) {
      userHasSorted.current = false;
    }
  }, [searchCriteria]);

  const initializeSorting = () => {
    // maintain sort settings if set by user
    if (userHasSorted.current && useExistingSortConfig()) {
      return;
    }

    setSortConfig({ ...defaultSortConfigOptions });
  };

  const useExistingSortConfig = (): boolean => {
    if ((sortConfig.sortByColumnName === 'originDeadhead' && !searchCriteria?.originCity) ||
      (sortConfig.sortByColumnName === 'destinationDeadhead' && !searchCriteria?.destinationCity)) {
      return false;
    }
    return true;
  };

  const handleOnSort = (sortByColumnName: string, sortOrder: SortOrder) => {
    let newSortOrder = sortOrder;

    if (sortByColumnName === recommendedFieldColumn) {
      newSortOrder = sortOrderFieldDefaults[sortByColumnName];
      setSortConfig({ ...sortConfig, sortByColumnName, sortOrder: newSortOrder, skipSorting: true });
      refreshLoads(true);
      // update to sortByColumnName - set default sort order
    } else if (sortConfig.sortByColumnName !== sortByColumnName) {
      newSortOrder = sortOrderFieldDefaults[sortByColumnName];
      setSortConfig({ ...sortConfig, sortByColumnName, sortOrder: newSortOrder, skipSorting: false });
    } else {
      // update to sortOrder
      setSortConfig({ ...sortConfig, sortOrder: newSortOrder });
    }

    // maintain users sort settings after performing searches
    userHasSorted.current = true;
    manualSortTriggered.current = true;
  };

  const onPagination = useCallback(({ pageSize, pageNumber }) => {
    dispatch(setPagination('find-loads-results', { pageNumber, pageSize }));
  }, []);

  const handleRefresh = useCallback(() => {
    dispatchSubmitSearchEvent(searchCriteria, UUID.UUID());
    doSearchActions(searchCriteria, user, false, findLoadsSendAnalyticsWeb);
    refreshTriggered.current = true;
    setSelectedLoad(null);
    dispatch(setPage('find-loads-results', 1));
    refreshLoads(true);
  }, [refreshLoads]);

  const loadDetailSelectedByUser = (load) => {
    updateSelectedLoad(load);
    if (findLoadsSendAnalyticsWeb) {
      const { pageNumber, pageSize } = results.pagination;
      publishLoadClickedEvent(load, pageNumber, pageSize, searchCorrelationId, user, carrier?.carrierCode);
    }
    !isWebBreakpoint && dispatch(showLoadDetailModal(load));
  };

  const setLoadDetailRef = ref => loadDetailRef.current = ref;
  const focusSelectedLoadResult = useCallback(() => {
    selectedLoadResultRef?.focus();
  }, [selectedLoadResultRef]);

  const renderCard = useCallback((load: AvailableLoadSummary) => {
    const isSelected = selectedLoad?.number === load.number;
    return <FindLoadsResult
      key={load.number}
      load={load}
      showOriginDeadhead={Boolean(searchCriteria?.originCity)}
      showDestinationDeadhead={Boolean(searchCriteria?.destinationCity)}
      setLoadResultRef={isSelected ? setSelectedLoadResultRef : null}
      isSelected={isSelected}
      selectLoad={loadDetailSelectedByUser}
      isMetric={user.isMetric()}
    />;
  },
    [searchCriteria, selectedLoad, isWebBreakpoint, searchCorrelationId, results.pagination, user],
  );

  const adjustedCardSortOptions = useMemo(() => {
    const cardSortOptions: CardSortOption[] = [
      { field: 'originStateProvinceCode', resourceKey: 'ORIGIN' },
      { field: 'pickStartTimeV2', resourceKey: 'PICK_UP_DATE' },
      { field: 'destinationStateProvinceCode', resourceKey: 'DESTINATION' },
      { field: 'deliverByDateTime', resourceKey: 'DELIVERY_DATE' },
      { field: 'weight', resourceKey: 'WEIGHT' },
      { field: 'distance', resourceKey: 'DISTANCE' },
      { field: 'equipmentCode', resourceKey: 'EQUIPMENT' },
      { field: 'endorsementsList', resourceKey: 'ENDORSEMENT' },
      { field: 'totalCost', resourceKey: 'RATE' },
      { field: recommendedFieldColumn, resourceKey: 'RECOMMENDED' }
    ];

    if (Boolean(searchCriteria?.originCity)) {
      cardSortOptions.push({ field: 'originDeadhead', resourceKey: 'ORIGIN_DEADHEAD' });
    }
    if (Boolean(searchCriteria?.destinationCity)) {
      cardSortOptions.push({ field: 'destinationDeadhead', resourceKey: 'DESTINATION_DEADHEAD' });
    }

    return cardSortOptions;
  }, [searchCriteria]);

  const searchPerformed = !!results?.loads;
  const loadsFound = displayLoads?.length > 0;
  const showFooter = loadsFound;
  const showLoadNotificationButton = findLoadsLoadNotificationWeb && !searchCriteria?.loadNumber;

  // Suggested Loads - Conditions to show
  // User first gets to the page, no search has been performed
  // User toggles between the SearchSelectorType (OriginDestination or LoadNumber)
  // User performs load num search and gets no results back. In this case it will automatically perform the recommended load search in the find-loads.epic
  const suggestedLoadsRetrieved = searchPerformed && results?.performedSuggestedLoadSearch;

  const shouldShowLoader = !searchCriteria && !searchPerformed && !error && !results?.performedSuggestedLoadSearch;

  useEffect(() => {
    mainLoader.hide();
    if (shouldShowLoader) {
      suggestedLoader.show();
    } else {
      suggestedLoader.hide();
    }
  }, [shouldShowLoader]);

  const filters = useMemo(() => {
    if (suggestedLoadsRetrieved) { return undefined; }
    let commonFilters = [<RefreshButton onClick={handleRefresh} />];
    if (searchType !== AvailableLoadSearchType.RELOADS) {
      commonFilters = [
        <FiltersButton searchCriteria={searchCriteria} />,
        ...commonFilters
      ];
    }
    return commonFilters;
  }, [suggestedLoadsRetrieved, handleRefresh, searchType]);

  if (suggestedLoadsRetrieved && !loadsFound) {
    return <EmptySplashResults />;
  }

  return (
    <>
      {searchPerformed &&
        <ReloadNotFoundBanner results={results} reload={searchCriteria?.reloadsCriteria?.selectedReloadLoadNumber} />
      }
      <LoadingIndicatorSpinner mainText="LOADING_PERSONALIZED_LOAD_RECOMMENDATIONS" isVisible={shouldShowLoader} />
      <ErrorBoundary>
        <AreaContext.Provider value={{ area: 'Radius/State/Province Results' }}>
          {searchPerformed &&
            <div className="find-loads-results results" data-testid="find-loads-results">
              <LazyBoundary>
                <DataTable
                  items={displayLoads}
                  viewFormatOverride={DataViewFormat.Card}
                  onSort={handleOnSort}
                  totalRecords={totalLoads}
                  pagination={!suggestedLoadsRetrieved && results.pagination}
                  hideControls={suggestedLoadsRetrieved}
                  sortConfig={sortConfig}
                  cardSortOptions={adjustedCardSortOptions}
                  renderFooter={showFooter ? null : <></>} // Default footer shows unless a custom renderFooter is provided.
                  aboveResultsContent={<div>{/*SMART MATCH STRING GOES HERE*/}</div>}
                  sortControlDisabled={sortConfig?.sortByColumnName === recommendedFieldColumn}
                  footerPosition={FooterPosition.belowTableContent}
                  headerPosition={HeaderPosition.aboveTableContent}
                  addRestrictedBanner={true}
                  addLoadNotifications={showLoadNotificationButton}
                  dataTableButton={filters}
                  paginationComponent={
                    !suggestedLoadsRetrieved && <Pagination totalItems={totalLoads} values={results.pagination} onPagination={onPagination} />
                  }
                  detailComponent={
                    loadsFound && isWebBreakpoint && selectedLoad && (
                      <LoadDetail
                        load={selectedLoad}
                        setLoadDetailRef={setLoadDetailRef}
                        focusSelectedLoadResult={focusSelectedLoadResult}
                        sendLDEvents={sendLDEvents}
                        position={positionOnPage}
                      />
                    )
                  }
                  suggestedHeader={
                    <OptionalTitle suggestedLoadsRetrieved={suggestedLoadsRetrieved} searchCriteria={searchCriteria} searchType={searchType} />
                  }
                >
                  {!loadsFound && <LoadsNotFound />}
                  {loadsFound && <CardView renderCard={renderCard} />}
                  {suggestedLoadsRetrieved &&
                    <EmbeddedContentCardFeed
                      location={EmbeddedContentCardLocations.SUGGESTED_LOADS}
                      purpose={EmbeddedContentCardPurposes.LOAD_PAY}
                      type={EmbeddedContentCardTypes.BANNER}
                    />}
                </DataTable>
              </LazyBoundary>
            </div>
          }
          <FindLoadsLazyModals sendLDEvents={sendLDEvents} />
        </AreaContext.Provider>
      </ErrorBoundary>
    </>
  );
};
