import { FindLoadsSearchForm } from 'pages/find-loads-ver2/search/find-loads-search.form';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { API_DATE_FORMAT } from 'app/globals/constants';
import { ConversionMultipliers } from 'shared/components/formatters/unit-conversion.formatter';

export const ConvertToSearchCriteria = (form: FindLoadsSearchForm, isMetric: boolean, abTestGroupMember: boolean = false): AvailableLoadSearchCriteriaJSON => {
  const values = form.value;

  return {
    ...getDateRangeParams(values.dateRange),
    ...getOriginParams(values.origin, isMetric),
    ...getDestinationParams(values.destination, isMetric),
    ...getEquipmentTypeParams(values.equipment),
    loadNumber: values.loadNumber || null,
    teamLoad: null,
    hazmatLoad: null,
    carrierTierAvailable: null,
    stfLoad: null,
    webExclusive: null,
    pageSize: abTestGroupMember ? 1000 : 5000
  };
};

const formatDate = (date?) => date ? date.format(API_DATE_FORMAT) : null;

const getDateRangeParams = (dateRange) => {
  return {
    pickupStart: formatDate(dateRange.start),
    pickupEnd: formatDate(dateRange.end)
  };
};

const convertRadius = (radius, isMetric: boolean) => {
  return isMetric ? Math.round(radius / ConversionMultipliers.Distance) : radius;
}

const getOriginParams = (origin, isMetric: boolean) => {
  if (!origin.place) {
    return {};
  }

  const radius = convertRadius(origin.radius, isMetric);

  return {
    originLatitude: origin.place?.coordinate?.latitude,
    originLongitude: origin.place?.coordinate?.longitude,
    originCity: origin.place?.cityName,
    originCountryCode: origin.place?.countryCode,
    originCountryName: origin.place?.countryName,
    originStateProvinceCode: origin.place?.stateProvinceCode,
    originRadiusMiles: radius || 1,
    originExampleZipCode: origin.place?.zipCodes?.[0]
  };
};

const getDestinationParams = (destination, isMetric: boolean) => {
  if (!destination.place) {
    return {};
  }

  const radius = convertRadius(destination.radius, isMetric);

  return {
    destinationLatitude: destination.place?.coordinate?.latitude,
    destinationLongitude: destination.place?.coordinate?.longitude,
    destinationCity: destination.place?.cityName,
    destinationCountryCode: destination.place?.countryCode,
    destinationCountryName: destination.place?.countryName,
    destinationStateProvinceCode: destination.place?.stateProvinceCode,
    destinationRadiusMiles: radius || 1,
    destinationExampleZipCode: destination.place?.zipCodes?.[0]
  };
};

const getEquipmentTypeParams = (equipment) => {
  return {
    mode: equipment.type,
    specializedEquipmentCode: equipment.extendedType === EquipmentType.All ? null : equipment.extendedType
  };
};