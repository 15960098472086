import { useEffect, useState } from 'react';
import { Modal } from 'shared/components/modal/modal.component';
import { Button } from 'shared/components/elements/elements.components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideModal } from 'shared/components/modal/modal.actions';
import { FindLoadsFiltersForm } from 'pages/find-loads-ver2/filters/find-loads-filters.form';
import { FindLoadsFiltersFormComponent } from 'pages/find-loads-ver2/filters/find-loads-filters-form.component';
import { useForceUpdate } from 'app/hooks/use-force-update.hook';
import { convertDistanceToMetric, convertWeightToMetric } from 'shared/components/formatters/unit-conversion.formatter';

import './find-loads-filters-modal.component.scss';

export const FIND_LOADS_FILTERS_MODAL = 'find-loads-filter';

interface FindLoadsFiltersModalProps {
  isMetric: boolean;
  searchCriteria: AvailableLoadSearchCriteriaJSON;
  onFiltersApplied: (form: FindLoadsFiltersForm) => void;
}

export const FindLoadsFiltersModal = ({ isMetric, searchCriteria, onFiltersApplied }: FindLoadsFiltersModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  const [form, setForm] = useState<FindLoadsFiltersForm>(null);

  useEffect(() => {
    const newForm = new FindLoadsFiltersForm();
    newForm.changes.subscribe(() => forceUpdate());

    if (searchCriteria) {
      newForm.disable(true);

      const convertNumber = (value: number, isMetric: boolean, convert: (val: number) => any) => {
        if (!value) {
          return '';
        }
        if (isMetric) {
          return Math.round(convert(value));
        }
        return value;
      };

      newForm.get('weight.min').setValue(convertNumber(searchCriteria.weightMin, isMetric, convertWeightToMetric));
      newForm.get('weight.max').setValue(convertNumber(searchCriteria.weightMax, isMetric, convertWeightToMetric));
      newForm.get('distance.min').setValue(convertNumber(searchCriteria.milesMin, isMetric, convertDistanceToMetric));
      newForm.get('distance.max').setValue(convertNumber(searchCriteria.milesMax, isMetric, convertDistanceToMetric));
      newForm.get('teamLoadsOnly').setValue(searchCriteria.teamLoad || false);
      newForm.get('hazmatLoadsOnly').setValue(searchCriteria.hazmatLoad || false);
      newForm.get('carrierTierAvailableOnly').setValue(searchCriteria.carrierTierAvailable || false);
      newForm.get('equipment.length').setValue(searchCriteria.equipmentLengthMax || '');
      newForm.get('hideStfLoads').setValue(searchCriteria.stfLoad === false); // "hideStfLoads" field is check when "stfLoad" is false.
      newForm.get('webExclusiveOnly').setValue(searchCriteria.webExclusive || false);

      newForm.enable(true);
    }
    setForm(newForm);

  }, [searchCriteria]);


  const onSubmit = () => {
    onFiltersApplied(form);
    dispatch(hideModal(FIND_LOADS_FILTERS_MODAL));
  };

  const footer = (
    <div className="find-loads-filters-actions">
      <Button id="find-loads-filters-apply" data-testid="find-loads-filters-apply" btnPrimary onClick={onSubmit}>
        {t('APPLY')}
      </Button>
      <Button id="find-loads-filters-clear" data-testid="find-loads-filters-clear" btnLink onClick={() => form.reset()}>
        {t('CLEAR_ALL')}
      </Button>
    </div>
  );

  return (
    <Modal
      name={FIND_LOADS_FILTERS_MODAL}
      header="FILTERS"
      footer={footer}
      isForm={true}
      track
    >
      {form &&
        <FindLoadsFiltersFormComponent form={form} isMetric={isMetric} />
      }
    </Modal>
  );
};

