import React from 'react';
import classNames from 'classnames';
import { ChangeEvent } from 'react';

import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { ComponentConnectorFactory, ResourceProps } from 'store/component-connector';
import { Option } from 'shared/components/elements/elements.components';
import { getSpecializedEquipmentTypes } from 'app/ref-data/ref-data.actions';
import { select } from 'store/selectors/state.selectors';

import './equipment-select.component.scss';

export interface EquipmentTypeValues {
  type: EquipmentType;
  extendedType: string;
}

interface OwnProps {
  values: EquipmentTypeValues;
  onChange: (values: EquipmentTypeValues) => any;
  onBlur?: () => any;
  withoutSpecialized?: boolean;
  idPrefix?: string;
}

interface ConnectStateProps {
  isEuropean: boolean;
  equipmentTypes: ReferenceDataJSON[];
  specializedEquipmentTypes: ReferenceDataJSON[];
}

interface ConnectDispatchProps {
  getSpecializedEquipmentTypes: (isEU: boolean, type) => any;
}

type ConnectResourceKeys = 'SELECT_EQUIPMENT' | 'SELECT_SPECIALIZED_EQUIPMENT';

type Props = OwnProps & ConnectStateProps & ConnectDispatchProps & ResourceProps<ConnectResourceKeys>;

export class EquipmentTypeSelectComponent extends React.Component<Props> {

  UNSAFE_componentWillMount() {
    if (this.isSpecializedEquipmentType(this.props.values.type)) {
      this.props.getSpecializedEquipmentTypes(this.props.isEuropean, this.props.values.type);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
    const values = this.props.values;
    const nextValues = nextProps.values;

    if (nextValues.type !== values.type && this.isSpecializedEquipmentType(nextValues.type)) {
      this.props.getSpecializedEquipmentTypes(this.props.isEuropean, nextValues.type);
    }
  }

  // noinspection JSMethodCanBeStatic
  private isSpecializedEquipmentType(type: EquipmentType) {
    return type === EquipmentType.Bulk || type === EquipmentType.Flatbed;
  }

  setEquipmentType = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as EquipmentType;
    const {values} = this.props;

    this.props.onChange({...values, type, extendedType: EquipmentType.All});

    if (this.isSpecializedEquipmentType(type)) {
      this.props.getSpecializedEquipmentTypes(this.props.isEuropean, type);
    }
  };

  setExtendedEquipmentType = (e: ChangeEvent<HTMLSelectElement>) => {
    const extendedType = e.target.value;
    const {values} = this.props;
    this.props.onChange({...values, extendedType});
  };

  render() {
    const {equipmentTypes, specializedEquipmentTypes, values, withoutSpecialized, resources, idPrefix} = this.props;

    return (
      <div className={classNames('equipment-select-controls', {'adjust-equipment-select-size': (values.type === EquipmentType.Flatbed || values.type === EquipmentType.Bulk)})}>
        <div className="equipment-type-dropdown">
          <select
            id={idPrefix ? idPrefix + "-equipment-type" : "equipment-type"}
            aria-label={resources.SELECT_EQUIPMENT}
            value={values.type}
            onBlur={this.props.onBlur}
            onChange={this.setEquipmentType}
          >
            <Option value="A" resource="ALL_EQUIPMENT_TYPES"/>
            {equipmentTypes.map(type =>
              <option key={type.code} value={type.code}>{type.value}</option>
            )}
          </select>
        </div>
        {!withoutSpecialized && (values.type === EquipmentType.Flatbed || values.type === EquipmentType.Bulk) &&
          <div className="extended-equipment-type-dropdown">
            <select
              id={idPrefix ? idPrefix + "-extended-type" : "extended-type"}
              aria-label={resources.SELECT_SPECIALIZED_EQUIPMENT}
              value={values.extendedType}
              onChange={this.setExtendedEquipmentType}>
              <Option value="A" resource="ALL_TYPES"/>
              {specializedEquipmentTypes.map(type =>
                <option key={type.code} value={type.code}>{type.value}</option>
              )}
            </select>
          </div>}
      </div>
    );
  }
}

export const EquipmentTypeSelect = ComponentConnectorFactory<OwnProps, ConnectStateProps, ConnectDispatchProps>()
  .withResources<ConnectResourceKeys>('SELECT_EQUIPMENT', 'SELECT_SPECIALIZED_EQUIPMENT')
  .combineStateSelectors(
    select.auth.carrier.isEuropean,
    select.refData.equipmentTypes,
    select.refData.specializedEquipmentTypes
  )
  .mapDispatchToProps({
    getSpecializedEquipmentTypes
  })
  .connect(EquipmentTypeSelectComponent);
